import { navigateToPaidPlans } from '../../../services/navigateToPaidPlans';
import { Pages } from '../../Location/LocationContext';
import { SelectedPaymentOption } from '../../../components/ChallengesPage/Widget/components/Pricing/interfaces';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { paidPlansPropsMap } from '../../PaidPlans/paidPlansPropsMap';
import {
  getChallengePaidPlans,
  getChallengeVisiblePaidPlans,
} from '@wix/challenges-web-library/dist/src';
import {
  getChallengeSlugFromLocation,
  locationProviderPropsMap,
} from '../../Location/locationProviderPropsMap';
import { ActionTypes } from '../../BI/interfaces';
import { participantAPI } from '@wix/challenges-web-api/dist/src/API';
import { biProviderPropsMap } from '../../BI/BIProviderPropsMap';
import { getPaymentType } from './userContextHelpers';
import { IUserProviderProps } from '../UserProvider';
import { CreatePaymentOrderResponse } from '@wix/ambassador-challenge-service-web/types';
import userTypeHandlers from './userTypeHandlers';
import { FedopsInteraction } from '../../../components/ChallengesPage/Widget/constants';
import { getChallengeId } from '../../ChallengeDataProvider/challengeDataProviderPropsMap';
import { getChallengeEligiblePaidPlans } from '../../../components/ChallengesPage/Widget/components/Pricing/helpers/getChallengeEligiblePaidPlans';

export async function payForChallenge(
  flowAPI: ControllerFlowAPI,
  userProvider: IUserProviderProps,
  paymentOption: SelectedPaymentOption,
  participantId: string,
  startDate: string,
  timeZone: string,
): Promise<{ userJoined: boolean }> {
  const location = locationProviderPropsMap(flowAPI);
  const challengeId = await getChallengeId(flowAPI);

  const bi = biProviderPropsMap({ flowAPI });
  const { participant } =
    await bi.beforeAndAfterAction<CreatePaymentOrderResponse>(
      ActionTypes.CREATE_PAYMENT_ORDER_ID,
      (actionId) =>
        participantAPI.createPaymentOrder({
          participantId,
          challengeId,
          startDate,
          paymentType: getPaymentType(paymentOption),
          timeZone,
          actionId,
        }),
    );

  await userProvider.updateParticipant(participant);

  console.log(
    'FFF2',
    userProvider.participant,
    userProvider.participant?.transitions?.[0]?.state,
    getPaymentType(paymentOption),
  );

  if (
    paymentOption === SelectedPaymentOption.PaidPlans &&
    userTypeHandlers.isJoinedAlready(
      userProvider.participant.transitions[0].state,
    )
  ) {
    return { userJoined: true };
  }

  if (paymentOption === SelectedPaymentOption.SinglePayment) {
    flowAPI.fedops.interactionStarted(FedopsInteraction.OpenPaymentPage);
    location.goToPage({
      challengeId: getChallengeSlugFromLocation(flowAPI),
      pageId: Pages.Payment,
    });
    return { userJoined: false };
  }

  if (paymentOption === SelectedPaymentOption.PaidPlans) {
    const t = flowAPI.translations.t;

    const paidPlans = await paidPlansPropsMap(flowAPI);
    flowAPI.fedops.interactionEnded(FedopsInteraction.JoinUserToChallenge);

    const challengeVisiblePaidPlans = getChallengeVisiblePaidPlans(
      challengeId,
      paidPlans.userPaidPlans,
    );
    const challengePaidPlans = getChallengePaidPlans(
      challengeId,
      paidPlans.userPaidPlans,
    );
    const challengeEligiblePaidPlans = getChallengeEligiblePaidPlans(
      paidPlans.eligiblePlans,
      paidPlans.userPaidPlans,
      challengeId,
    );

    await navigateToPaidPlans({
      pageId: Pages.Details,
      url: getChallengeSlugFromLocation(flowAPI),
      planIds: (challengeVisiblePaidPlans.length // we should send visible paid plans, or if this is only hidden, send one of them
        ? challengeVisiblePaidPlans.filter((plan) => {
            return !challengeEligiblePaidPlans.find(
              (elPlan) => elPlan.id === plan.id,
            );
          })
        : challengePaidPlans.slice(0, 1)
      ).map((paidPlan) => paidPlan.id),
      titleText: t(
        'pricing.payment-option.subscription.paid-plan.thank-you.title',
      ),
      buttonText: t(
        'pricing.payment-option.subscription.paid-plan.thank-you.button-title',
      ),
      contentText: t(
        'pricing.payment-option.subscription.paid-plan.thank-you.content',
      ),
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
    });

    return { userJoined: false };
  }
}
