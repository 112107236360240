import { challengeAPI } from '@wix/challenges-web-api/dist/src/API';
import { ControllerFlowAPI, IHttpClient } from '@wix/yoshi-flow-editor';
import { getChallengeSlugFromLocation } from '../Location/locationProviderPropsMap';
import { syncInstance } from '../../services/instance';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { isAvailableForJoinImmediately } from '../../selectors/challenges';
import { getMockedChallenge } from '../main/getMockedChallenges';
import { Challenges } from '../../editor/types/Experiments';
import {
  challenge,
  ChallengeServerlessData,
} from '../../api/challenges/challenge.api';

export interface IChallengeDataProps {
  isGroupsInstalled: boolean;
  challengeData: ChallengeServerlessData & {
    isAvailableForJoinImmediately: boolean;
  };
}

export const shouldReportSentry = (err) => err?.httpStatus !== 403;

export async function handleUserLogin(flowAPI: ControllerFlowAPI) {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async (user) => {
    let challengeResponse: ChallengeServerlessData = {
      challenge: null,
    };
    const challengeId = getChallengeSlugFromLocation(flowAPI);

    syncInstance(flowAPI);

    try {
      if (challengeId) {
        challengeResponse = await challengeAPI.getChallenge({
          challengeId,
        });
      }
    } catch (error) {
      handleError({
        error,
        context: 'onUserLogin.getChallenge',
        preventSentryReport: !shouldReportSentry(error),
      });
    }

    flowAPI.controllerConfig.setProps({
      challengeData: {
        ...challengeResponse,
        ...{
          isAvailableForJoinImmediately: isAvailableForJoinImmediately(
            challengeResponse.challenge,
          ),
        },
      },
    });
  });
}

export async function requestChallenge(
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
): Promise<ChallengeServerlessData> {
  const mockedChallenge = getMockedChallenge(slugOrChallengeId, flowAPI);

  if (mockedChallenge) {
    return { challenge: mockedChallenge, badgesData: { badges: [], count: 0 } };
  }

  if (!slugOrChallengeId) {
    return;
  }

  const isServerlessChallengeExperimentEnabled = flowAPI.experiments.enabled(
    Challenges.enableServerlessChallenge,
  );

  if (isServerlessChallengeExperimentEnabled) {
    return requestChallengeServerless(flowAPI.httpClient, slugOrChallengeId);
  }

  const ch = await requestChallengeHttp(slugOrChallengeId);
  return { challenge: ch };
}

export async function requestChallengeHttp(id: string) {
  const findChallengeResponse = await challengeAPI.listChallenges({
    slug: id,
  });

  if (findChallengeResponse.totalCount === 1) {
    return findChallengeResponse?.memberChallenges?.[0]?.challenge;
  }

  // fallback to getting challenge by challengeId just in case
  return (
    await challengeAPI.getChallenge({
      challengeId: id,
    })
  ).challenge;
}

export async function requestChallengeServerless(
  httpClient: IHttpClient,
  id: string,
): Promise<ChallengeServerlessData> {
  const res = await httpClient.request(challenge(id));
  return res.data;
}
